import storage from 'store2';
export const isBrowser = () => typeof window !== 'undefined';

export const getUser = () =>
  isBrowser() && storage.get('user')
    ? storage.get('user')
    : {};

export const setUser = user => isBrowser() && storage.set('user', user);

export const isLoggedIn = () => !!getUser().email;

export const logout = firebase => {
  return new Promise(resolve => {
    firebase.auth().signOut().then(() => {
      setUser({});
      resolve();
    });
  })
}