import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import firebase from 'gatsby-plugin-firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { setUser, isLoggedIn } from '../../utils';


const Login = () => {
  const [fb, setFirebase] = useState();

  useEffect(() => {
    setFirebase(firebase);
  }, [])

  if (isLoggedIn()) {
    navigate(`/`);
  }

  function getUiConfig(auth) {
    return {
      signInFlow: 'popup',
      signInOptions: [
        auth.GoogleAuthProvider.PROVIDER_ID,
        auth.EmailAuthProvider.PROVIDER_ID
      ],
      // signInSuccessUrl: '/app/profile',
      callbacks: {
        signInSuccessWithAuthResult: result => {
          console.log(result)
          setUser(result.user);
          navigate('/');
        }
      }
    };
  }

  return (
    <div>
      {fb && <StyledFirebaseAuth uiConfig={getUiConfig(fb.auth)} firebaseAuth={fb.auth()} />}
    </div>
  );

}

export default Login;
